<template>
  <div>
    <b-modal
      id="modal-edit-product-kit"
      title="แก้ไขข้อมูลอุปกรณ์"
      ref="modal-edit-product-kit"
      no-close-on-backdrop
      hide-footer
    >
      <h4>{{ selected_product_kit_id.product_name }}</h4>
      <hr />
      <h5>ค่าเช่า</h5>
      <b-input-group append="฿" class="mb-1">
        <!-- <b-form-input v-model="form.rental_price" /> -->
        <b-form-input v-model="selected_product_kit_id.rental_price" />
      </b-input-group>
      <hr />
      <h5>ลำดับที่แสดง</h5>
      <b-input-group class="mb-1">
        <b-form-input v-model="selected_product_kit_id.sorting_order" />
        <!-- <b-form-input v-model="selected_product_kit_id.rental_price" /> -->
      </b-input-group>
      <b-button variant="relief-success" @click="edit"> แก้ไขอุปกรณ์ </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BAlert,
} from "bootstrap-vue";
import ProductSuggest from "./ProductSuggest.vue";
import _ from "lodash";

export default {
  props: ["selected"],

  components: {
    BButton,
    ProductSuggest,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BAlert,
  },
  watch: {
    selected: {
      deep: true,
      handler(newInfo) {
        this.selected_product_kit_id = newInfo;
      },
    },
  },
  data() {
    return {
      form: {},
      selected_product_kit_id: {},
    };
  },
  created() {},
  methods: {
    edit() {
      this.$http({
        method: "POST",
        url: `/product/edit-kit-product/`,
        data: this.selected_product_kit_id,
      }).then(async (x) => {
        this.$refs["modal-edit-product-kit"].hide();
        await this.$root.$emit("edit-product-complete");
      });
    },
  },
};
</script>
