<template>
  <div>
    <div class="d-flex justify-content-between">
      <div><h3>อุปกรณ์ที่ให้ไปด้วย</h3></div>
      <div class="d-flex justify-content-between">
        <b-button
          class="mb-1"
          variant="relief-success"
          size="sm"
          v-b-modal.modal-add-accessories-to-product
        >
          <feather-icon icon="PlusIcon" /> เพิ่มอุปกรณ์
        </b-button>
      </div>
    </div>
    <b-card no-body>
      <b-table responsive="sm" :items="info" :fields="fields">
        <template #cell(action)="data">
          <confirm-delete-button @delete="deleteSub" :data="data.item._id" />
        </template>
        <template #cell(name)="data">
          <div v-if="data.item.info">{{ data.item.info.name }}</div>
        </template>
        <template #cell(lease_price)="data">
          <div v-if="data.item.info.lease_price">
            {{ data.item.info.lease_price | number }} บาท
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-add-accessories-to-product"
      ref="modal-add-accessories-to-product"
      title="เพิ่มอุปกรณ์ที่ให้ไปด้วย"
      no-close-on-backdrop
      size="md"
      hide-footer
    >
      <vue-autosuggest
        :suggestions="filteredOptions"
        :limit="10"
        :input-props="{
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: 'ค้นหาอุปกรณ์ที่ให้ไปด้วย',
        }"
        @input="debouncedOnChange"
        @selected="selectHandler"
        class="mb-2"
      >
        <template slot-scope="{ suggestion }">
          <span class="my-suggestion-item"
            >{{ suggestion.item.name }} - ( {{ suggestion.item.lease_price }}฿
            )</span
          >
        </template>
      </vue-autosuggest>

      <div class="d-flex justify-content-between">
        <b-button variant="relief-warning" size="sm" v-b-modal.add-modal>
          <feather-icon icon="PlusIcon" /> สร้างอุปกรณ์เสริมใหม่
        </b-button>

        <b-button
          variant="relief-success"
          size="sm"
          @click="addAccessoriesInclude()"
        >
          เพิ่มอุปกรณ์เสริม
        </b-button>
      </div>
      <!-- <b-button
        variant="relief-success"
        size="sm"
        @click="addAccessoriesInclude()"
      >
        <feather-icon icon="PlusIcon" /> เพิ่มอุปกรณ์
      </b-button> -->
    </b-modal>
    <add-modal :only_close="true" />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ConfirmDeleteButton from "../../components/ConfirmDeleteButton.vue";

import AddModal from "../../accessories/component/AddModal.vue";

import { VueAutosuggest } from "vue-autosuggest";
import _debounce from "lodash.debounce";
import { BTable } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    VueAutosuggest,
    BTable,
    ConfirmDeleteButton,
    AddModal,
  },
  computed: {
    debouncedOnChange() {
      return _debounce(this.onInputChange, 400);
    },
  },
  data() {
    return {
      info: null,
      filteredOptions: [],
      selected: null,
      fields: ["name", "lease_price", "action"],
    };
  },
  created() {
    this.loadData();
    this.loadAccessoriesSuggest("");
  },
  methods: {
    deleteSub(x) {
      console.log(x.data);
      if (x.confirm) {
        this.$http({
          method: "DELETE",
          url: `/product/accessories/${x.data}`,
        }).then((x) => {
          this.loadData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบอุปกรณ์",
              icon: "CheckIcon",
              text: "ลบอุปกรณ์สำเร็จแล้ว",
              variant: "success",
            },
          });
        });
      }
    },
    loadData() {
      this.$http({
        method: "GET",
        url: `/product/accessories/${this.$route.params.id}`,
      }).then((x) => {
        console.log("loaddata /product/accessories/", x.data);
        this.info = x.data.data;
      });
    },
    addAccessoriesInclude() {
      this.$http({
        method: "POST",
        url: `/product/accessories/add`,
        data: {
          product_id: this.$route.params.id,
          accessories_id: this.selected._id,
        },
      }).then((x) => {
        this.$refs["modal-add-accessories-to-product"].hide();
        this.loadData();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "เพิ่มอุปกรณ์",
            icon: "CheckIcon",
            text: "เพิ่มอุปกรณ์สำเร็จแล้ว",
            variant: "success",
          },
        });
      });
    },
    selectHandler(data) {
      this.selected = data.item;
    },
    loadAccessoriesSuggest(text) {
      this.$http({
        method: "POST",
        url: `/accessories/list/filter`,
        data: {
          text: text,
        },
      }).then((x) => {
        this.filteredOptions = [
          {
            data: x.data.data,
          },
        ];
      });
    },
    onInputChange(text) {
      this.loadAccessoriesSuggest(text);
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
