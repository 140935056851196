<template>
  <!-- title="อุปกรณ์ในรุ่นนี้" -->
  <div>
    <div class="d-flex justify-content-between">
      <div><h3>รายการอุปกรณ์จัดชุด</h3></div>
      <div class="d-flex justify-content-between">
        <b-button
          class="mb-1"
          variant="relief-success"
          size="sm"
          v-b-modal.modal-add-product-kit
        >
          <feather-icon icon="PlusIcon" /> เพิ่มอุปกรณ์
        </b-button>
      </div>
    </div>
    <b-card no-body>
      <b-table responsive="sm" :items="info.products" :fields="fields">
        <template #cell(action)="data">
          <b-button
            size="sm"
            variant="relief-warning"
            @click="selected_product_kit_id = data.item"
            class="mr-1 mb-1"
            v-b-modal.modal-edit-product-kit
          >
            Edit
          </b-button>
          <b-button
            class="mb-1"
            size="sm"
            variant="relief-danger"
            @click="showDeleteConfirmBox(data.item._id)"
          >
            Delete
          </b-button>
        </template>
      </b-table>
    </b-card>
    <product-add-modal />
    <edit-product-kit-modal :info="info" :selected="selected_product_kit_id" />

    <!-- <product-sub-edit :form="selected_sub_product" /> -->
  </div>
</template>

<script>
import { BCard, BCardText, BBadge, BButton } from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import ProductAddModal from "./AddProductModal.vue";
import EditProductKitModal from "./EditProductKitModal.vue";

export default {
  props: ["info"],
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BButton,
    ProductAddModal,
    EditProductKitModal,
  },

  data() {
    return {
      product_id: this.$route.params.id,
      selected_product_kit_id: null,
      fields: [
        { key: "product_name", label: "อุปกรณ์" },
        { key: "original_price", label: "ราคาเดิม" },
        { key: "rental_price", label: "ราคาจัดชุด" },
        { key: "action", label: "Action" },
      ],
    };
  },

  methods: {
    showDeleteConfirmBox(product_kit_id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ลบอุปกรณ์ชิ้นนี้", {
          title: "ลบอุปกรณ์",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http({
              method: "POST",
              url: `/product/delete-kit-product`,
              data: {
                product_kit_id: product_kit_id,
              },
            }).then(async (x) => {
              await this.$root.$emit("edit-product-complete");
            });
          }
        });
    },
  },
  watch: {},
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        ready: "success",
        repair: "info",
        lost: "danger",
        on_case: "warning",
        disable: "info",
        sold: "danger",
        /* eslint-enable key-spacing */
      };
      return (web_status) => statusColor[web_status];
    },
  },
};
</script>

<style></style>
